.cardTeam {
    content: '';
    box-sizing: border-box;
    position:relative;
    padding: 0;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    left: 50%;
    transform: translateX(-54%);
}

.cardTeam img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.cardTeam h3{
    padding: 0px;
    margin-top: 40px;
}

.TeamCarousel .react-multi-carousel-list {
    height: fit-content;
}

.TeamCarousel .react-multiple-carousel__arrow {
    transform: translateX(0);
}

.TeamCarousel .react-multiple-carousel__arrow::before {
    color: #0A123A;
    scale: 1.5;
}

.TeamCarousel .react-multi-carousel-dot-list {
    top: 38%;
}

.TeamCarousel .react-multi-carousel-dot button{
    border: 1px solid #0A123A;
    background-color: transparent;
}

.TeamCarousel .react-multi-carousel-dot--active button{
    background-color: #0A123A;
}
