.footer {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
    width: 100%;
    position: relative;
    flex-direction: column;
    background: #030A30;
}
.footer .container {
    content: '';
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}


.footer .imgFooter img {
    width: 180px;
    height: 180px;
}

.footer .iconFooter {
    text-align: center;
}

.footer .iconFooter img {
    cursor: pointer;
    width: 17px;
    height: 17px;
    margin : 17px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(106%) contrast(101%);
}

.footer .iconFooter img:hover {
    scale: 1.3;
}

.footer .txtFooter {
    padding-bottom: 30px;
}

.footer .txtFooter p {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}