  .bg-hid {
    display: none !important;
  }


  .navbar {
    position: fixed;
    text-align: center;
    width: 100%;
    color: white;
    z-index: 10;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .underlined,
  .underlinedB {
    padding: 5px 30px !important;
  }

  .underlinedB {
    border-left: 1px solid #C9AB5B;
  }



  .underlined:focus{
  color: white;
  }

  .underlined:hover,
  .underlinedB:hover {
    font-weight: 600;
    }


  /* OFFCANVAS */

  .offcanvas {
    background-color: transparent;
  }

  .offCanvas1 {
    color: white;
    background: #131D56;
    border-left: 2px solid #ffffff50;
    height: 100%;
  }


  .hrOffcanvas {
  border: none;
  background-color: #C9AB5B;
  height: 2px !important;
  margin-top: 10px;
  }

  .rowOffcanvas,
  .rowOffcanvas2,
  .rowOffcanvas3 {
    font-family:'Nunito',sans-serif;
    margin-bottom: 15px;
  }

  .closeRight {
    vertical-align: middle;
    text-align: right;
  }

  .rowOffcanvas2 {
    font-size: 14px;
  }

  .rowOffcanvas3 {
    font-size: 11px;
  }

  .navbar-toggler,
  .navbar-toggler:focus
  {
    border: none;
    box-shadow: 0;
  }

  /* Icons */


.iconNav {
    text-align: center;
}

.iconNav img {
    cursor:pointer;
    width: 17px;
    height: 17px;
    margin : 17px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(106%) contrast(101%);
}

.iconNav img:hover {
  scale: 1.3;
}

.txtNav p {
    text-align: center;
}

.txtNav h3 {
    font-family: 'Grenze', sans-serif;
    color: #C9AB5B;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .logout-mobile{
    position:absolute;
    left:50%;
    transform: translateX(-50%);
  }
}