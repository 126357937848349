.Home1 {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
    width: 100%;
    min-height: 100vh;
    position: relative;
    flex-direction: column;
}
.Home1 .containerClass,
.Home1 .container {
    content: '';
    position: relative;
    width: 100%;
    height: 100%;
    padding: calc(10px + 1vw);
}

.Home1 .container1 {
    padding-top: 0;
}

.Home1 .containerNP {
    content: '';
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
}

.buttonSection {
    width: 100%;
    display: flex;
    position: relative;
}

/* Intro  */

.Home1 .containerClass .introSC {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

.Home1 .imgCT1 {
    text-align: center;
    vertical-align: middle;
}

@media (max-width: 480px) {
    .Home1 .imgCT1 img {
        width: 238px;
    }
}

@media (min-width: 480px) and (max-width: 600px) {
    .Home1 .imgCT1 img {
        width: 321px;
        height: 321px;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .Home1 .imgCT1 img {
        width: 411px;
        height: 411px;
    }
}

@media (min-width: 1024px) {
    .Home1 .imgCT1 img {
        width: 548px;
        height: 548px;
    }
}

.Home1 .introSC .H2Bringing{
    padding: 10px 0;
    padding-top: 0;
}

.Home1 .containerClass .introSC p {
    text-align: center;
}



/* Focuses */

.Home1 .container .focusesSC {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 6, 26, 0.3);
    border-top-left-radius: 30px ;
    border-top-right-radius: 30px ;
    display: flex;
}

.Home1 .bgColor2 {
    background-color: #ffffff;
    min-height: 400px;
    color: #131D56;
}

.Home1 .imgCT {
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.Home1 .imgCT img {
    position: relative;
    width: 180px;
}

.Home1 .focusesSC .cards {
    align-items: center;
    max-width: 400px;
    padding: 30px;
}

.Home1 .focusesSC .cards h3{
    height: 75px;
}

.Home1 .focusesSC .cards p{
    height: 120px;
    margin-bottom: 0;
}


/* Section Mint */

.Home1 section .mintSC {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 30px ;
    border-bottom-right-radius: 30px ;
}

@media (max-width: 480px) {
.Home1 .txtJustify p,
.Home1 .txtJustify h3 {
    text-align: center !important;
}
}

@media (min-width: 480px) {
    .Home1 .txtJustify p {
        text-align: justify !important;
    }
    }

.Home1 .txtJustify h3,
.Home1 .txtJustify p {
    color: #131D56;
    text-align: justify;
}

.Home1 .txtJustify h2 {
    color: #131D56;
}

.ElrondPrice {
    position: relative;
    display: flex;
    height: fit-content;
    width: fit-content;
    margin-bottom: 20px;
}

@media (max-width:480px) {
    .ElrondPrice,
    .formBox,
    .Home1 .mintSC .button1 {
        text-align: center;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
    }
}

.ElrondPrice img {
    position: relative;
    padding: 0;
    margin-left: 10px;
    width: 25px;
    height: 25px;
}

.ElrondPrice p {
    position: relative;
    width: fit-content;
    padding: 0 !important;
    margin: 0;
    margin-left: 20px;
    top: 2px;
}

.Home1 .mintingPrice {
    font-family: 'Grenze', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 20px;
}

.Home1 .imgCT2 {
    text-align: center;
    vertical-align: middle;
    display: flex;
    padding: 10px;
}

.Home1 .imgCT2 img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

@media (min-width: 900px) {
    .Home1 .imgCT2 img {
        height: 380px;
        width: 380px;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .Home1 .imgCT2 img {
        width: 300px;
    }
}

@media (max-width: 600px) {
    .Home1 .imgCT2 img {
        width: 240px;
    }
}

.Home1 .mintSC .formBox {
    position: relative;
    width: 100%;
    padding-left: 10px;
}

.Home1 .mintSC .buttonBox {
    position: relative;
    width: 100%;
    padding: 10px;
}

.Home1 .mintSC .formCSS {
    position: relative;
    box-sizing: border-box;
    width: 80px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    outline:none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* Section Meet the Knights Caroussel */

.Home1 .bgColor1 {
    background: #030A30;
    box-shadow: inset 4px 4px 34px rgba(0, 0, 0, 0.25);
    min-height: 400px;
}

.Home1 .cardsCarou {
    position: relative;
    width: 100%;
    padding: 0;
}



/* Section Prepare your weapons */

.Home1 section .prepareSC {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    background: rgba(2, 6, 26, 0.3);
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

}

@media (min-width: 1200px) {
    .Home1 section .prepareSC {
        padding-left: 300px;
        padding-right: 300px;
    }
}

@media (max-width: 1200px) {
    .Home1 section .prepareSC {
        padding-left: auto;
        padding-right: auto;
    }
}


/* Section TEAM */

.Home1 .imgCT3 {
    text-align: center;
    vertical-align: middle;
}

.Home1 .imgCT3 img {
    width: 180px;
    border-radius: 50%;
}

.Home1 .containerNP .pCaps {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    height:50px;
    margin-bottom:0;
}

.Home1 .containerNP p {
    color: #131D56;
}

/* Sword Animation */

.swordsAnimation {
    position: relative;
    width: 70%;
    display: block;
    margin-bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 500px) {
    .swordsAnimation {
        height: 150px;
    }
}
@media (min-width: 500px) {
    .swordsAnimation {
        height: 333px;
    }
}

.swordsAnimation .sword {
    position: absolute;
    width: 548px;
    height: 332.93px;
}

@media (max-width: 500px) {
    .swordsAnimation .sword {
        width: 238.05px;
        height: 145.6px;
    }
}
@media (min-width: 500px) {
    .swordsAnimation .sword {
        width: 548px;
        height: 332.93px;
    }
}

.swordsAnimation .swordLeft {
    opacity: 0.2;
}

@media (max-width: 500px) {
    .swordsAnimation .swordLeft {
        left: -150px;
    }
}
@media (min-width: 500px) {
    .swordsAnimation .swordLeft {
        left: -250px;
    }
}

.swordsAnimation .swordRight {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    opacity: 0.2;
}

@media (max-width: 500px) {
    .swordsAnimation .swordRight {
        Right: -150px;
    }
}
@media (min-width: 500px) {
    .swordsAnimation .swordRight {
        Right: -250px;
    }
}
