.StakeKnights {
    content: '';
    box-sizing: border-box;
    position:relative;
    padding: 0;
    padding-left: auto;
    padding-right: auto;
    text-align: center;
    display: flex;
    padding: 20px;
}

.StakeKnights .KnightCards {
    position:relative;
    align-items: center;
    border: none;
    width: 280px;
    height: auto;
    left:50%;
    transform: translateX(-50%);
}

.card-text {
    height: 125px;
}


.StakeKnights .KnightCards img {
    margin:15px;
}

.StakeKnights .rewardKNT {
    position:relative;
    flex-wrap: nowrap;
    display: flex;
    width: fit-content;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.StakeKnights .KnightCards .buttonsSkate {
    position:relative;
}

@media (min-width:600px) and (max-width:1000px) {
.StakeKnights .KnightCards .buttonsSkate .button1 {
    left: 50%;
    transform: translateX(-50%);
}
}

/* OTHER MEDIA QUERRY */

@media (max-width:1000px) {
    .StakeKnights .KnightCards .buttonsSkate {
        flex-wrap: wrap;
        display: flex;
    }  
}

@media (min-width:1000px) {
    .StakeKnights .KnightCards .buttonsSkate {
        flex-wrap: nowrap;
        display: flex;
    }  
}

.buttonsSkate .buttonCenter,
.buttonsSkate .button1 {
    margin:10px;
    margin-bottom: 30px; 
    left: auto;
    transform: translateX(0);
}

.App .StakeKnights p {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
}


.StakeKnights .specialP {
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
text-align: center;
text-transform: uppercase;
}


/* GALLERY */

.buttonsSkate .categories {
    background: rgba(19, 29, 86, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 1px 11px;
    margin: 10px;

    display: flex;
    align-items: center;
    text-align: center;

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #fff;
}