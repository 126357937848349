.button1 {
    position: relative;
    display: block;
    width: fit-content;
    height: fit-content;
    margin: 20px 0px;
    z-index: 1;
    padding: 8px 25px;
    overflow: hidden;
    cursor:pointer;

    background: #131D56;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.buttonCenter {
    left: 50%;
    transform: translateX(-50%);
}


.button1 .buttonBlue {
    margin: 0px 10px;
    display: flex;
    text-transform:uppercase;
    align-items: center;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
}

.button1:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.button1:hover .buttonBlue {
    color: #131D56;
}

.buttonGrey {
    position: relative;
    background: #E5E5E5;
    border: 1px solid #ABB3DB;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #131D56;
}