.searchLine {
    position: relative;
    margin-top: 40px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
}

.searchLine .boxInput { 
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.searchLine input { 
    border: none;
}

.searchLine input,
.searchLine input::placeholder { 
    text-transform: uppercase;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #3A3A3A;
}

.searchLine input:focus {
outline: none;
}

.searchLine div {
    width: fit-content;
    position: relative;
}

.searchLine img {
    width: 25px;
    height: auto;
    margin-left: 20px;
}

.rarityButtons {
    position: relative;
    flex-wrap: nowrap;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
}

.App .rarity h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
}

.rarity .rarityBTN {
    padding: 1px 26px;
    margin: 10px;
    gap: 10px;
    background: #F4F4F4;
    border: 1px solid rgba(108, 112, 132, 0.5);
    border-radius: 30px;
    width: 110px;
    text-align: center;

    color: #131D56;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.rarity .activeRarity {
background: #131D56;
color: #fff;
}

.Stake1 .card-text {
    height: 45px;
    padding: 0;
}
