@font-face {
  font-family: 'Grenze';
  src: url('./Assets/Fonts/Grenze/Grenze-Bold.woff2') format('woff2'),
      url('./Assets/Fonts/Grenze/Grenze-Bold.woff') format('woff'),
      url('./Assets/Fonts/Grenze/Grenze-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./Assets/Fonts/Nunito/Nunito-Regular.woff2') format('woff2'),
      url('./Assets/Fonts/Nunito/Nunito-Regular.woff') format('woff'),
      url('./Assets/Fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('./Assets/Fonts/Nunito/Nunito-SemiBold.woff2') format('woff2'),
      url('./Assets/Fonts/Nunito/Nunito-SemiBold.woff') format('woff'),
      url('./Assets/Fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Grenze', 'Nunito', sans-serif;
  background: transparent;
  overflow: hidden;
}

.bgDegrade {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  /* background: linear-gradient(180deg, #152BA0 0%, #63542b 100%); */
  /* background: linear-gradient(180deg, #152BA0 0%, #7a0060 100%); */
  background: linear-gradient(180deg, #152BA0 0%, #0A123A 100%);
}

/* h2 */

.App h2 {
  font-family: 'Grenze', sans-serif;
  font-weight: 700;
  line-height: 55px;
  text-align: center;
  padding: 20px;
}

@media (max-width: 480px) {
  .App h2 {
    font-size: 30px;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .App h2 {
    font-size: 36px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .App h2 {
    font-size: 38px;
  }
}

@media (min-width: 1024px) {
  .App h2 {
    font-size: 50px;
  }
}

/* h3 */

.App h3 {
font-family: 'Nunito', sans-serif;
color: #C9AB5B;
font-weight: 600;
text-align: center;
text-transform: uppercase;
font-size: 20px;
line-height: 20px;
padding: 20px 10px;
padding-top: 30px
}

/* @media (max-width: 480px) {
  .App h3 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .App h3 {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .App h3 {
    font-size: 18px;
    line-height: 20px;
  }
}

@media (min-width: 1024px) {
  .App h3 {
    font-size: 20px;
    line-height: 20px;
  }
} */

/* P */

.App p {
  font-family: 'Nunito', sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
}

/* @media (max-width: 480px) {
  .App p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .App p {
    font-size: 13px;
    line-height: 18px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .App p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1024px) {
  .App p {
    font-size: 14px;
    line-height: 20px;
  }
} */