.MeetKnights {
    content: '';
    box-sizing: border-box;
    position:relative;
    padding: 0;
    width: 90%;
    padding-left: auto;
    padding-right: auto;
}

.MeetKnights .KnightCards {
    background-color: transparent !important;
    align-items: center;
    display: flex;
    border: none;
    width: 280px;
    left:50%;
    transform: translateX(-50%);
}

.react-multi-carousel-list {
    height: 350px;
    overflow-y: visible;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.react-multi-carousel-track  {
    padding-left: 20px;
    padding-right: 20px;
}
.react-multiple-carousel__arrow {
    background:none;
    scale: 1.5;
}

.react-multiple-carousel__arrow:hover {
    background:none;
}

.react-multiple-carousel__arrow--left {
    Left:0;
    transform: translateX(-20%);
}

.react-multiple-carousel__arrow--right {
    right:0;
    transform: translateX(20%);
}

.MeetKnights .KnightCards img {
    width: 280px;
    height: 280px;
}

.react-multi-carousel-dot button {
    position: relative;
    top: -10px;
}

.react-multi-carousel-dot button {
    background: #030A30;
    border: 1px solid #FFFFFF;
}

.react-multi-carousel-dot--active button {
    background: #fff;
    border: none;
    scale : 1.3;
}