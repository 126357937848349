.popup-connexion{
  color: #131D56;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  

  position: fixed;
  z-index: 500;
  top: 20%;
  left: 0;
  left: 50%;
  transform: translate(-50%);
  /* background: #F1648D; */
  background: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px;
  padding-right: 20px;
  border: 2px solid #C9AB5B;
  box-shadow: 3px 3px 3px #1f1b3dca;
  text-align: center;
  align-items: center;
  border-radius: 15px;
  z-index: 2;
}

.backgroundPopup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #0000009f;
}


/* Ajout  */

@media (max-width: 768px) {
  .popup-connexion {
      Top: 20%;
      width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .popup-connexion {
    Top: 30%;
    width: 65%;
  }
}

@media (min-width: 1024px) {
  .popup-connexion {
    Top: 30%;
    width: 50%;
  }
}

.buttonPopup {
  font-size: 20px;
  width: fit-content;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.buttonPopup2 {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.btn {
  font-size: 20px;
  color: #131D56;
  padding:0;
}

.buttonPopup img {
  width: 50px;
}

.btn-light {
  color: #131D56;
  background-color: #ffffff;
  border: 1px solid #131D56;
  border-radius: 5px;
}

.m-1 {
  margin: 0;
}

.popup-connexion p{
  margin-top: 20px;
  font-size: 20px !important;
}

.popup-connexion .pupupTitle {
  position: relative;
  display: flex;
  width: 100%;

}

.popup-connexion .pupupTitle h4{
  margin-bottom: 20px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  font-size: 30px;
}

.closeButton {
  position: relative;
  cursor: pointer;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .closeButton {
    left: 80%;
  }
}

@media (min-width:780px) and (max-width:1024px) {
  .closeButton {
    left: 75%;
  }
}

@media (max-width:780px) {
  .closeButton {
    left: 60%;
  }
}

.closeButton img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.popup-connexion .closeButton p{
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px !important;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.dapp-core-ui-component .btn-primary {
  color: #131D56 !important;
  border: none !important;
  background-color: transparent !important;
}

.dapp-core-ui-component .btn {
  box-shadow: none !important;
}

.buttonSpan {
  background-color: white;
  padding: 8px;
  padding-bottom: 12px;
  border-radius: 3px;
}

.modal-content {
  border: 2px solid #131D56;
  color : #131D56;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.wallect-connect-login_card {
  border: none;
}