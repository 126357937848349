.ErrorPageCSS {
    content: '';
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    position: relative;
    flex-direction: column;
    background: linear-gradient(180deg, #152BA0 0%, #0A123A 100%);
    color: white;
}

.ErrorPageCSS .container {
    content: '';
    position: relative;
    width: 100%;
    height: 100%;
    padding: calc(10px + 1vw);
    max-width: 700px;
}

.ErrorPageCSS .container .LogoKnights {
    position: relative;
    top: -30px;
    display: flex;
    text-align: center;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
}

.ErrorPageCSS .container h3 {
    position: relative;
    top: -50px;
    padding: 0;
    margin: 0;
    line-height: 24px;
}


.ErrorPageCSS .container .ContentRouage {
    position: absolute;
    width: 600px;
    height: 300px;
    transform: translate(-50%, 0);
    box-sizing: content-box;
    text-align: center;
}

@media (min-width: 600px) {
    .ErrorPageCSS .container .ContentRouage {
    scale: 1;
    left: 50%;
    top: 55%;
    }
}

@media (max-width: 600px) {
    .ErrorPageCSS .container .ContentRouage {
    scale: 0.9;
    left: 40%;
    top: 55%;
    }
}

@media (max-width: 480px) {
    .ErrorPageCSS .container .ContentRouage {
    scale: 0.8;
    left: 35%;
    top: 55%;
    }
}

.ErrorPageCSS .container .R1 {
    left: 0;
    top: 20px;
    position: relative;
    width: 260px;
    transform: rotate(320deg);
    animation: spin1 5s infinite linear;
    -webkit-animation: spin1 5s infinite linear;
}

.ErrorPageCSS .container .R2 {
    left: 10px;
    top: 0;
    position: relative;
    width: 200px;
    transform: rotate(300deg);
    animation: spin2 5s infinite linear;
    -webkit-animation: spin2 5s infinite linear;
}

/* SPIN 1 */

@keyframes spin1 {
    0% {
        transform: rotate(300deg);
    }
    100% {
        transform: rotate(660deg);
    }
  }

  @-webkit-keyframes spin1 {
    0% {
        transform: rotate(300deg);
    }
    100% {
        transform: rotate(660deg);
    }
  }

/* SPIN 2 */

  @keyframes spin2 {
    0% {
        transform: rotate(680deg);
    }
    100% {
        transform: rotate(320deg);
    }
  }

  @-webkit-keyframes spin2 {
    0% {
        transform: rotate(680deg);
    }
    100% {
        transform: rotate(320deg);
    }
  }