.collectiblesSec {
    position: relative;
    min-height: 0;
}

@media (max-width:480px) {
    .collectiblesSec h2 {
        margin-top: 50px;
        padding: 30px;
    }
}

@media (min-width:480px) {
    .collectiblesSec h2 {
        margin-top: 100px;
        padding: 50px;
    }
}

.collectiblesSec .ContourBlanc {
    position: relative;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 30px; 
    margin-bottom: 50px;
}

.collectiblesSec .ContourBlanc .textCollectibles {
    position: relative;
    width: 400px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
}

.collectiblesSec .ContourBlanc p {
    color: #131D56;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.collectiblesSec .knightStaking {
    position: relative;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
}

.collectiblesSec .knightStaking h2 {
    position: relative;
    color: #131D56;
    margin : 0px;
    padding: 30px;
    width: fit-content;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

.buttonRow1 {
    position: relative;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width:1024px) {
.buttonRow1 .buttonCenter {
    left: 50%;
    transform: translateX(-50%); 
    }
}

@media (min-width:1024px) {
    .buttonRow1 .buttonCenter {
    left: 0;
    transform: translateX(0); 
    }
}

/*  */

@media (max-width:1024px) {
    .buttonRow1 .buttonGrey {
        left: 50%;
        transform: translateX(-50%); 
        }
    }
    
    @media (min-width:1024px) {
        .buttonRow1 .buttonGrey {
        left: 0;
        transform: translateX(0); 
        }
    }

    /*  */

@media (max-width:1024px) {
    .buttonRow1 {
        flex-wrap: wrap;
        display: block;
    }  
}

@media (min-width:1024px) {
    .buttonRow1 {
        flex-wrap: nowrap;
        display: flex;
    }  
}

/* @media (max-width:1024px) {
.buttonRow1 .button1 {
    left: 50%;
    transform: translateX(-50%);
}
} */

.buttonRow1 .buttonCenter,
.buttonRow1 .button1 {
    /* left: 0;
    transform: translateX(0); */
    margin:10px;
    margin-bottom: 30px;
}
