.Admin{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
    width: 100%;
    min-height: 100vh;
    position: relative;
    flex-direction: column;
    background: linear-gradient(180deg, #152BA0 0%, #0B1545 100%);
}

.content-admin{
    position: relative;
    background: #ffffff95;
    border: 2px solid #C9AB5B;
    border-radius: 15px;
    margin: 60px;
    padding: 20px;
    top: 54px;

    color: #131D56;
    font-size: 18px;
    font-weight: 600;
}

.content-admin .admin-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}

.content-admin .buttonBlue {
    color: #fff;
}

.content-admin .row {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #fff;
}

.inputAdmin {
    width: fit-content;
    min-width: 200px;
    height: 40px;
    position: relative;
    border: 1px solid #131D56;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
}